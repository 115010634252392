/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { ACTION_TYPES, getPriceStr, toTitleCase } from "./utils-offplan-map"
 import { useLocation } from "@reach/router"
 import { seo_fallback_img } from "../../site/utils"

 import _ from "lodash"
 export { ACTION_TYPES, ACTION_DESC_TYPES, getPriceStr } from "./utils-offplan-map";
 
 const SeoResults = ({ description, lang, meta, title, searchParams, location, pcategorytype }) => {
 
   const {areas, bedrooms, pType, type, price, commType, mustInclude } = searchParams;
   //console.log("mustInclude", mustInclude)
   var replace_url = pcategorytype+"_"+pType;
 
   const actionStr = ACTION_TYPES[replace_url];
   const priceStr = getPriceStr(price)
   const bedRooms = searchParams.bedrooms
   const pricerange = searchParams.price
   const searcharea = areas
   const capitalize = (s) => {
     if (typeof s !== 'string') return ''
     return s.charAt(0).toUpperCase() + s.slice(1)
   }
 
   let propertyType = ""
 
   if(type == "") {
     propertyType = "new developments"
  } else {
     propertyType = type+"s".replace(/-/g, ' ').toLowerCase();
   }
   let desc = "";
   if(searcharea === "dubai") {
      desc="Explore our latest collection of new developments "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +". Contact White & Co Real Estate in "+toTitleCase(areas.replace(/-/g, ' '))+" to find the right new developments for you.";
   }else {
     desc="Explore our range of new developments "+actionStr+ toTitleCase(areas.replace(/-/g, ' ')) +". Contact White & Co Real Estate to find the right new developments "+actionStr+ toTitleCase(areas.replace(/-/g, ' '));
   }
   if(propertyType !="new developments") {
     desc="Looking for a "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+"? White & Co Real Estate are here to help you in your new developments journey.";
   }
   if(bedrooms) {
    desc="Explore new developments "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +" with more than "+searchParams.bedrooms+" bedrooms here. Contact our expert real estate brokers today to get assistance in finding the right new developments in "+toTitleCase(areas.replace(/-/g, ' '));
   }

   if(pricerange.min || pricerange.max) {
       desc="Locate the right new developments "+ actionStr + toTitleCase(areas.replace(/-/g, ' ')) +priceStr+" with White & Co Real Estate. Book an appointment with one of our new developments experts in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if (propertyType !="new developments" && (searchParams.price.min || searchParams.price.max)) {
      desc= "Check out the latest collection of "+propertyType.replace(/-/g, ' ')+ actionStr + priceStr +" in "+ toTitleCase(areas.replace(/-/g, ' '))+ priceStr+" with White & Co Real Estate in "+toTitleCase(areas.replace(/-/g, ' '));
   }
   if((pricerange.min || pricerange.max) && bedrooms) {
    desc="Explore the list of new developments "+ actionStr+"  in "+toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" with White & Co Real Estate and request a viewing for the new developments that interests you.";
   } 
   if (propertyType !="new developments" && bedrooms) {
     desc="Find the latest collection of "+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms. Contact White & Co Real Estate in "+toTitleCase(areas.replace(/-/g, ' '))+", to arrange a viewing.";
   }
   if(propertyType !="new developments" && (pricerange.min || pricerange.max) && bedrooms) {

     desc=""+propertyType.replace(/-/g, ' ')+ actionStr + toTitleCase(areas.replace(/-/g, ' '))+" with more than "+searchParams.bedrooms+" bedrooms "+priceStr+" available through White & Co Real Estate. Contact one of our real estate brokers for assistance in finding your new developments in "+toTitleCase(areas.replace(/-/g, ' '))+".";
   }

//    else {
//      // price
//      if (price.min || price.max){
//        desc = propertyType + actionStr + areas + "."
//      }
//    }
 
     //
     let titlePropertyType = ""
 
     if(type == "") {
         titlePropertyType = "new developments"
     } else {
         titlePropertyType =  capitalize(type+"s".replace(/-/g, ' '))
     }
 
     let bedroomType = ""
 
     if ( bedrooms ) {
      bedroomType = ' with more than '+bedrooms+' bedrooms'
    }
    let Amenities = ""
    if ( mustInclude && mustInclude ) {
      var includecap = mustInclude.map( a => a.charAt(0).toUpperCase() + a.substr(1) );
      Amenities = " with "+capitalize(includecap.join(' and '))
    }
     let descTitle = toTitleCase(titlePropertyType.replace(/-/g, ' ')) + actionStr + toTitleCase(areas.replace(/-/g, ' ')) + bedroomType + priceStr + Amenities.replace(/-/g, ' ')
     //
 
      // console.log("Props Desc => ", desc);
 
      description = desc
 
      // console.log("Props Desc => ", desc);
      var { pathname } = useLocation()

      if (pathname == "/home/" || pathname == "/home" )
        pathname = "/"
      if (pathname == "/off-plan-properties-map/for-sale" || pathname == "off-plan-properties-map/for-sale" )
        pathname = "/off-plan-properties-map/for-sale/in-dubai/"
      if (pathname == "/off-plan-properties-map/for-rent/" || pathname == "off-plan-properties-map/for-rent" )
        pathname = "/off-plan-properties-map/for-rent/in-dubai/"
    
      var curentUrl = '';
      curentUrl = process.env.GATSBY_SITE_URL+pathname;
    
      // set no index for result pagination
      let hasPagination = false;
      if (pathname !== undefined && pathname) {
        let pageStr = pathname.split('page-');
        hasPagination = pageStr[1] || false;
        if (pageStr[0] !== undefined) {
         curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
        }
      }
    
      if (!curentUrl.endsWith('/'))
            curentUrl = curentUrl + '/';
    
      //remove multiple slashes
      curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
      //set lowercase url
      curentUrl = curentUrl.toLowerCase();
   
   
   let metaItems = [
     {
       name: `description`,
       content: description.replace(/And/g, "and"),
     },
     {
       property: `og:title`,
       content: descTitle.replace(/And/g, "and"),
     },
     {
       property: `og:description`,
       content: description.replace(/And/g, "and"),
     },
     {
       property: `og:type`,
       content: `website`,
     },
     {
      name: `og:url`,
      content: curentUrl,
    },
    {
      property: `og:image`,
      content: seo_fallback_img,
    },
    {
      property: `og:image:secure_url`,
      content: seo_fallback_img,
    },
    {
      property: `og:image:type`,
      content: `image/jpeg`,
    },
    {
      property: `og:image:width`,
      content: `600`,
    },
    {
      property: `og:image:height`,
      content: `400`,
     },
     {
      property: "twitter:image",
      content: seo_fallback_img,
     },
     {
       name: `twitter:card`,
       content: `summary_large_image`,
     },
     {
       name: `twitter:creator`,
       content: `White & Co Real Estate`,
     },
     {
       name: `twitter:title`,
       content: descTitle.replace(/And/g, "and"),
     },
     {
       name: `twitter:description`,
       content: description.replace(/And/g, "and"),
     },
   ].concat(meta);
 
 
   // set no index for result pagination
  //  let hasPagination = false;
  //  if (location !== undefined && location.pathname) {
  //    let pageStr = location.pathname.split('page-');
  //    hasPagination = pageStr[1] || false;
  //  }
  //  if ( hasPagination ) {
  //    metaItems.push({
  //      name: `robots`,
  //      content: `noindex`,
  //    })
  //  }
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       link={[
        {
          href: curentUrl,
          rel: "canonical"
       }]}
       title={descTitle.replace(/And/g, "and")}
       titleTemplate={`%s | White & Co Real Estate`}
       meta={metaItems}
     />
   )
 }
 
 SeoResults.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
   title: ``,
   searchParams: [],
 }
 
 SeoResults.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
   searchParams: PropTypes.any,
 }
 
 export default SeoResults
 