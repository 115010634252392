import React, { useEffect } from "react"
import { Container, Col, Row, Form } from "react-bootstrap"
// import { Link } from "gatsby";
import algoliasearch from "algoliasearch/lite"
import {
  connectStats,
  InstantSearch,
  RefinementList,
  connectSearchBox,
  connectMenu,
  connectRange,
  Configure,
  connectSortBy,
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  CustomMarker,
} from "react-instantsearch-dom-maps"
import $ from "jquery"
import qs from "qs"
import { navigate } from "@reach/router"
import Select from "react-select"
import Header from "../components/Header/Header"
import OffplanFilterSearch from "../components/SearchResults/OffplanFilterSearch"
import Footer from "../components/Footer/Footer"
import SeoResults, {
  ACTION_TYPES,
  ACTION_DESC_TYPES,
  getPriceStr,
} from "../components/SearchResults/seoResultsOffplanMap"
import {
  parseSearchUrl,
  propertyH1,
  propertyDesc,
  savedSearchParams,
} from "../components/SearchResults/utils-offplan-map"
import {
  customStyles,
  resiSalesPropertyType,
  resiLettingsPropertyType,
  priceminRangeResiSale,
  priceminRangeResiRent,
  pricemaxRangeResiSale,
  pricemaxRangeResiRent,
} from "../components/common/propertyUtils"
import NoImg from "../images/no-image.png"
import { getWhatsAppURL, scrollToElement } from "../site/utils"
import useDeviceMedia from "../hooks/useDeviceMedia"

//
// const mycookies = new Cookies();
const updateAfter = 500
// const DEBOUNCE_TIME = 500;

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

// var includeSold = false;
// var includeLet = false;

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index_name = `${process.env.GATSBY_ALGOLIA_OFFPLAN_INDEX_NAME}`
const defaultArea = "dubai"
//

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  return (
    <div className="filter-search-input-box">
      <div className="dropdown">
        <i className="icon-location-input"></i>
        <Form.Control
          type="search"
          placeholder="Street, area or postcode"
          id="formBasicEmail"
          onChange={event =>
            refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())
          }
          value={currentRefinement.replace(/-/g, " ")}
        />
      </div>
    </div>
  )
})
// Searchbox

// Property type
const buildingSelectbox = ({
  items,
  currentRefinement,
  refine,
  department,
  type,
}) => {
  var propertyListDropdown = resiSalesPropertyType
  if (department === "residential" && type === "lettings") {
    propertyListDropdown = resiLettingsPropertyType
  }

  return (
    <Select
      name="building"
      options={propertyListDropdown}
      // value={{ value: 0, label: "All" }}
      placeholder="Property Type"
      onChange={e => {
        refine(e.value, e.label)
      }}
      isSearchable={false}
    />
  )
}

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Min Price
const minpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  type,
}) => {
  var minPriceRange = priceminRangeResiSale
  if (type === "lettings") {
    minPriceRange = priceminRangeResiRent
  }
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minPriceRange = minPriceRange.filter(
      x => parseInt(x.value) < parseInt(currentRefinement.max)
    )
  }

  minpriceval = currentRefinement.min
  return (
    <Select
      name="minprice"
      options={minPriceRange}
      isSearchable={false}
      placeholder="Min Price"
      onChange={e => {
        minpriceval = e.value
        if (e.value === "0") {
          if (maxpriceval !== "0") {
            refine({
              max: maxpriceval,
            })
          } else refine(e.value, e.label)
        } else {
          refine({
            min: e.value,
            max: maxpriceval,
          })
        }
      }}
    />
  )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Min Price

// Max Price
const maxpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
  type,
}) => {
  var maxPriceRange = pricemaxRangeResiSale
  if (type === "lettings") {
    maxPriceRange = pricemaxRangeResiRent
  }
  let maxpricedisable = ""
  if (currentRefinement.min !== undefined) {
    maxPriceRange = maxPriceRange.filter(
      x => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min)
    )
  }
  if (currentRefinement.min == "400000") {
    maxpricedisable = "disabled"
  }
  maxpriceval = currentRefinement.max
  return (
    <Select
      name="maxprice"
      options={maxPriceRange}
      defaultValue={{ value: "0", label: "Max Price" }}
      placeholder="Max Price"
      classNamePrefix={"select-opt"}
      isSearchable={false}
      onChange={e => {
        maxpriceval = e.value
        if (e.value === "0") {
          if (minpriceval) {
            refine({
              min: minpriceval,
            })
          } else refine(e.value, e.label)
        } else {
          refine({
            min: minpriceval,
            max: e.value,
          })
        }
      }}
    />
  )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Max Price

// Sort by
const sortbyStyle = {
  control: base => ({
    ...base,
    boxShadow: "none",
    border: "none",
  }),
}

const CustomSortBy = ({ items, refine, currentRefinement }) => (
  <div className="results-sort-wrapper d-flex align-items-center">
    <span>Sort:</span>
    <Select
      options={items}
      isSearchable={false}
      placeholder={"Most Recent"}
      className={"select-control"}
      styles={customStyles}
      defaultValue={currentRefinement || ""}
      onChange={e => {
        refine(e.value, e.label)
      }}
      components={{
        DropdownIndicator: () => <i className="icon icon-select-dropdown"></i>,
        IndicatorSeparator: () => null,
      }}
    />
  </div>
)

const CustomSortByFilter = connectSortBy(CustomSortBy)
// Sort by

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Map results
const MapResults = () => {
  return (
    <>
      <div id="map" className="map off-plan">
        <GoogleMapsLoader
          apiKey={process.env.GATSBY_MAPS_API_KEY}
          // apiKey={''}
        >
          {google => <MapResultswrapper google={google} />}
        </GoogleMapsLoader>
      </div>
    </>
  )
}

var InfoWindow
var onClickMarker
var html

const MapResultswrapper = props => {
  InfoWindow = new props.google.maps.InfoWindow()
  const { isMobile, isTablet } = useDeviceMedia()

  onClickMarker = ({ hit, marker }) => {
    $(".map-marker-" + hit.objectID)
      .addClass("active")
      .siblings()
      .removeClass("active")
    if (InfoWindow.getMap()) {
      InfoWindow.close()
    }

    const element = document.getElementById("map")
    scrollToElement(element, isMobile ? 70 : isTablet ? 90 : 120)

    // property details url structure
    let uriStr = ""

    if (hit.search_type === "sales") {
      uriStr = `off-plan-property-for-sale/`
    } else if (hit.search_type === "lettings") {
      uriStr = `off-plan-property-for-rent/`
    }
    // property details url structure

    // available days
    let currFullDate = new Date()
    let currYear = currFullDate.getFullYear()
    let currMonth = currFullDate.getMonth() + 1
    let currDate = currFullDate.getDate()
    let currDateCal = currYear + "-" + currMonth + "-" + currDate
    let propertyDate = hit.AvailableFrom
    let availableFrom = ""
    let date1 = new Date(propertyDate).getTime()
    let date2 = new Date(currDateCal).getTime()

    if (date1 > date2) {
      var timeDiff = Math.abs(date1 - date2)
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
      availableFrom = diffDays
    } else {
    }
    // available days

    // display address
    var propertyAddressFinal = ""

    if (hit.display_address === undefined) {
      propertyAddressFinal = hit.display_address
    } else {
      var propertyAddress = hit.display_address
      var propertySplit = propertyAddress.split(",")

      for (var i = 0; i < propertySplit.length; i++) {
        // Trim the excess whitespace.
        propertySplit[i] = propertySplit[i]
          .replace(/^\s*/, "")
          .replace(/\s*$/, "")
        // Add additional code here, such as:
        propertyAddressFinal = propertySplit[0]
      }
    }

    //   html = '<a href="/'+uriStr+hit.slug+'-'+hit.objectID+'"><div class="property-card"><div class="property-card-wrapper"><div class="property-img"><img src="'+(hit.images && (hit.images.length != 0) ? hit.images[0]['328x234'] : NoImg)+'" alt="property" /><div class="property-details"><div class="property-price">AED '+(hit.price ? hit.price.toLocaleString() : "")+''+(hit.search_type === "lettings" ? hit.price_qualifier : "")+'</div><div class="property-display-address">'+(hit.display_address ? hit.display_address : "")+'</div><div class="location-details d-flex align-items-center"><i class="icon icon-map-pin"></i><span>'+(hit.area ? hit.area : "")+'</span></div></div></div></div></div></a>';
    html =
      '<a href="/' +
      uriStr +
      hit.slug +
      "-" +
      hit.objectID +
      '"><div class="property-card off-plan"><div class="property-card-wrapper"><div class="property-img"><img src="' +
      (hit.images && hit.images.length != 0
        ? hit.images[0]["328x234"]
        : NoImg) +
      '" alt="property" /><div class="property-details"><div class="property-price">' +
      (hit.display_address ? hit.display_address : "") +
      '</div><div class="location-details d-flex align-items-center"><i class="icon icon-map-pin"></i><span>' +
      (hit.area ? hit.area : "") +
      '</span></div><ul class="off-plan-list"><li class="list-details">From AED ' +
      hit.price.toLocaleString() +
      '</li><li class="list-details">' +
      hit.title +
      '</li><li class="list-details">Q4 - 2024 Handover</li></ul><ul class="list-inline off-plan-contact-list d-flex align-items-center"><li class="list-inline-item"><a href="tel:' +
      hit?.negotiator_details?.phone +
      '"><span>' +
      hit?.negotiator_details?.phone +
      '</span></a></li><li class="list-inline-item"><a href="' +
      getWhatsAppURL(hit?.negotiator_details?.phone) +
      '" class="d-flex align-items-center"><i class="icon icon-team-whatsapp"></i><span>WhatsApp</span></a></li></ul></div></div></div></div></a>'

    InfoWindow.setContent(html)

    InfoWindow.open(marker.getMap(), marker)
    $(".gm-ui-hover-effect").on("click", function () {
      $(".icon-property_map_marker.active").removeClass("active")
    })
  }

  return (
    <>
      <GeoSearch
        google={props.google}
        enableRefineOnMapMove={false}
        initialZoom={10}
        // initialPosition={{
        //   lat: 25.426,
        //   lng: 51.50835,
        // }}
      >
        {({ hits }) => (
          <div>
            {hits.map(hit => (
              <>
                <CustomMarker
                  anchor={{ x: 0, y: -5 }}
                  key={hit.objectID}
                  position={hit._geoloc}
                  hit={hit}
                  className={
                    "icon-property_map_marker map-marker-" + hit.objectID
                  }
                  onClick={({ marker }) => {
                    onClickMarker({ hit, marker })
                  }}
                />
              </>
            ))}
          </div>
        )}
      </GeoSearch>
    </>
  )
}
// Map results

// Create URL
export const createURL = (props, state, location) => {
  let myarea = state.menu

  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    state.refinementList &&
    !state.refinementList?.search_areas?.length === 0

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = "in-dubai/"
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""

  if (
    state.refinementList &&
    state.refinementList?.search_areas &&
    state.refinementList?.search_areas?.length != 0
  ) {
    areaPath =
      typeof state.refinementList?.search_areas === "string"
        ? "in-" + state.refinementList?.search_areas + "/"
        : "in-" + state.refinementList?.search_areas?.join("-and-") + "/"
  }

  const queryParameters = {}

  // Property path
  if (state.menu) {
    if (myarea["building"]) {
      propertypath = "type-" + myarea["building"] + "/"
    }
  }
  // Property path

  // Bedrooms & Price range path
  if (state.range) {
    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath =
          "between-" +
          state.range.price.min +
          "-and-" +
          state.range.price.max +
          "/" //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + "/" //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "above-" + state.range.price.min + "/" //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/"
    }
    if (state.sortBy === index_name + "_price_desc") {
      sortPath = "sortby-price-desc/"
    }
    if (state.sortBy === index_name + "_most_recent") {
      sortPath = "sortby-most-recent/"
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${areaPath}${propertypath}${pricePath}${queryString}${pagePath}${sortPath}`

  if ("/" === myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)

  return `${location}${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState, location) =>
  searchState ? `${createURL(props, searchState, location)}` : ""

const urlToSearchState = (location, multiArea = true) => {
  if (location) {
    var query = ""
    let pathUri_main = ""

    let a1 = location.pathname.indexOf("/off-plan-properties-map/for-sale") > -1
    let a2 = location.pathname.indexOf("/off-plan-properties-map/for-rent") > -1

    if (a1 == true) {
      pathUri_main = location.pathname.split(
        "/off-plan-properties-map/for-sale"
      )
    } else if (a2 == true) {
      pathUri_main = location.pathname.split(
        "/off-plan-properties-map/for-rent"
      )
    } else {
      pathUri_main = location.pathname.split(location)
    }

    var areaVal = ""
    var bedVal = 10
    var bathVal = 10
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (
          pathUri[vi].indexOf("in-") >= 0 &&
          pathUri[vi].indexOf("in-") <= 1
        ) {
          let areaStr = pathUri[vi]
          areaVal = areaStr.replace("in-", "")
          if (areaVal === defaultArea) {
            areaVal = ""
          }
          if (multiArea) {
            areaVal = areaVal.split("-and-")
          }
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        //   Bathrooms
        if (pathUri[vi].indexOf("-and-more-bathrooms") >= 0) {
          bathVal = pathUri[vi].replace("-and-more-bathrooms", "")
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name + "_price_desc"
          }

          if (sortVal_filt === "most-recent") {
            sortVal = index_name + "_most_recent"
          }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    query += `&menu[building]=` + propertyval
  }

  // Area
  if (Array.isArray(areaVal) && areaVal.length > 0) {
    for (let v = 0; v <= areaVal.length; v++) {
      if (typeof areaVal[v] !== "undefined") {
        query += `&refinementList[search_areas]=` + areaVal[v]
      }
    }
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate

class OffplanResultsMapTemplate extends React.Component {
  constructor(props) {
    super(props)
  }

  //
  state = {
    searchState: urlToSearchState(
      typeof window !== "undefined" ? window.location : ""
    ),
    selectedAreas: [],
    // userObjects: [],
    // mapview: false,
    // isOpen: false,
    // popupType: "",
    // membDet:""
  }

  componentDidMount() {
    // load on top
    $(document).ready(function () {
      $("html, body").scrollTop(0)
    })
    // load on top
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      navigate(
        searchStateToUrl(this.props, searchState, this.props.locationname),
        searchState
      )
    }, updateAfter)
    this.setState({ searchState })
  }
  //

  // Grid view
  gridView = e => {
    navigate(
      this.props.location.pathname.replace(
        "off-plan-properties-map",
        "off-plan-properties"
      )
    )
  }
  // Grid View

  render() {
    // Capitalize
    const capitalize = s => {
      if (typeof s !== "string") return ""
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    // Capitalize

    const searchParams = parseSearchUrl(
      this.props.ptype,
      this.props.fullpathname,
      this.props.pcategorytype
    )

    const h1 = propertyH1(searchParams, this.props.pcategorytype)

    const h1desc = propertyDesc(searchParams)

    const areaName = searchParams.areas

    const pType = searchParams.pType

    let propertyType = ""
    if (searchParams.type == "") {
      propertyType = "properties "
    } else {
      propertyType = searchParams.type
    }

    const actionStr = ACTION_TYPES[pType]

    var description = `Explore our latest collection of ${capitalize(
      propertyType
    )} ${actionStr} ${
      searchParams.areas
    }. Contact White & Co Real Estate agents in ${
      searchParams.areas
    } to find the right property for you.`

    const isPageLoadBg = typeof this.props.fullpathname === "undefined"

    let mystatus = ""
    if (this.props.pstatustype) {
      if (Array.isArray(this.props.pstatustype)) {
        mystatus =
          '(status:"' + this.props.pstatustype.join('" OR status:"') + '")'
      } else {
        mystatus = '(status:"' + this.props.pstatustype + '")'
      }
    }

    return (
      <React.Fragment>
        <SeoResults
          title={h1}
          searchParams={searchParams}
          description={description}
          location={this.props.fullpathname}
          pcategorytype={this.props.pcategorytype}
        />
        <div className="search-results-template">
          <Header layout={"search-template"} />

          <InstantSearch
            indexName={index_name}
            searchClient={searchClient}
            searchState={this.state.searchState}
            onSearchStateChange={this.onSearchStateChange}
            createURL={createURL}
            routing="true"
          >
            <div className="d-none">
              {/* <RefinementList
                                attribute="search_type"
                                defaultRefinement={[this.props.ptype]}
                            />
                            <RefinementList
                                attribute="department"
                                defaultRefinement={[this.props.pcategorytype]}
                            />
                            <RefinementList
                                attribute="status"
                                defaultRefinement={[this.props.pstatustype]}
                            />
                            <RefinementList
                                attribute="publish"
                                defaultRefinement={[true]}
                            /> */}
              {this.state.selectedAreas?.length > 0 && (
                <RefinementList
                  attribute="search_areas"
                  defaultRefinement={this.state.selectedAreas}
                />
              )}
            </div>

            <Configure
              hitsPerPage={1000}
              filters={`publish:true AND search_type:${this.props.ptype} AND department:${this.props.pcategorytype} AND ${mystatus}`}
            />

            <OffplanFilterSearch
              propertyType={this.props.ptype}
              department={this.props.pcategorytype}
              setAreaList={areas => this.setState({ selectedAreas: areas })}
              searchState={this.state.searchState}
              propertyMap={"map"}
            />

            <div className="d-none">
              <SearchBox />
              <CustomminpriceSelectbox
                attribute="price"
                type={this.props.ptype}
              />
              <CustommaxpriceSelectbox
                attribute="price"
                type={this.props.ptype}
              />
              <CustombuildingSelectbox
                attribute="building"
                type={this.props.ptype}
                department={this.props.pcategorytype}
              />
            </div>

            <div className="search-results-content">
              <Container>
                <Row>
                  <Col>
                    <h1 className="search-results-heading">
                      {h1.replace(/-/g, " ")}
                    </h1>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center search-results-heading-section">
                  <Col lg={6} md={6} className="d-flex align-items-center">
                    <div className="search-results-count">
                      <CustomStats /> results
                    </div>
                    <div className="d-md-none d-flex">
                      <div className="results-divider"></div>
                      <a
                        href="javascript:void(0)"
                        onClick={this.gridView}
                        className="d-flex align-items-center map-view-link"
                      >
                        <i className="icon icon-grid-pin"></i> Grid View
                      </a>
                    </div>
                  </Col>
                  <Col lg={6} md={6} className="d-md-block d-none">
                    <div className="d-flex justify-content-end align-items-center search-sort-wrapper">
                      <a
                        href="javascript:void(0)"
                        onClick={this.gridView}
                        className="d-flex align-items-center map-view-link"
                      >
                        <i className="icon icon-grid-pin"></i> Grid View
                      </a>
                    </div>
                  </Col>
                </Row>
              </Container>

              <div className="mapresults">
                <MapResults />
              </div>
            </div>
          </InstantSearch>

          <Footer categoryType="category1" />
        </div>
      </React.Fragment>
    )
  }
}

export default OffplanResultsMapTemplate
