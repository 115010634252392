import React from "react";
import OffplanResultsTemplate from "../../../templates/offplan-results-map-template";

const OffplanPropertySaleMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <OffplanResultsTemplate 
                locationname="/off-plan-properties-map/for-sale/" 
                location={location}
                pcategorytype="off_plan" 
                ptype="sales" 
                ptypetag="for-sale" 
                pstatustype="active" 
                // pstatustype= {['For Sale','Exchanged','Under Offer']}
                status="active"
                fullpathone={fullpathone} 
                fullpathname={fullpath}
            />
        </React.Fragment>
    )
}

export default OffplanPropertySaleMap