import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Modal, InputGroup } from "react-bootstrap";
import Select from "react-select";
import $ from "jquery";
import _ from "lodash"
import { isEmpty, filter, find, isArray, isString, get, indexOf } from "lodash"
import { navigate } from "@reach/router"
import { navigate as gatsbyNavigate } from "gatsby";
import { propertySaleRentOptions, propertyTypes, completionRange, bedroomsRange, bathroomsRange, resiSalesPropertyType, resiLettingsPropertyType, priceminRangeResiSale, pricemaxRangeResiSale, priceminRangeResiRent, pricemaxRangeResiRent,  customStyles, sortFilterOffplan } from "../common/propertyUtils";
import SearchListBox from "./PredictiveSearch/search-multiarea";
import AreaListBox from "./PredictiveSearch/AreaList";
import './assets/styles/_index.scss';

const OffplanFilterSearch = (props) => {

    const [fullscreen, setFullscreen] = useState(true);
    const [filterShow, setFilterShow] = useState(false);

    function handleFilterShow() {
        setFilterShow(true);
        setAreaVal('')
    }

    // 
    const [areaVal, setAreaVal] = useState('');
    const [minpriceVal, setMinPriceVal] = useState('');
    const [maxpriceVal, setMaxPriceVal] = useState('');
    const [propertyval, setPropertyVal] = useState('');
    const [completionval, setCompletionVal] = useState('');
    const [sortval, setSortVal] = useState('');
    const [minPriceList, setMinPriceList] = useState([]);
    const [maxPriceList, setMaxPriceList] = useState([]);
    const [selectedMinPriceValue, setSelectedMinPriceValue] = useState(0);
    const [selectedMaxPriceValue, setSelectedMaxPriceValue] = useState(0);

    // Multi area search
    const [areasArr, setAreasArr] = useState([]);
    const [areasList, setAreasList] = useState([]);
    // 

    // 
    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (_.isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }
    // Multi area search

    // Min price
    const onChangeMinPrice = (e) => {
        setSelectedMinPriceValue(e.value);
    }
    // Min price

    // Max Price
    const onChangeMaxPrice = (e) => {
        setSelectedMaxPriceValue(e.value);
    }
    // Max Price

    // Sales price list
    var minPrice = [];
    var maxPrice = pricemaxRangeResiSale.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

    priceminRangeResiSale.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPrice.push(items));

    if (minPrice.length > 0) {
    } else {
        minPrice.push(priceminRangeResiSale[0])
    }

    if (maxPrice.length > 0) {
    } else {
        maxPrice.push(pricemaxRangeResiSale[0])
    }
    // Sales price list

    // Lettings price list
    var minPriceLettings = [];
    var maxPriceLettings = pricemaxRangeResiRent.filter(c => (c.value === "0" || parseInt(c.value) > parseInt(selectedMinPriceValue)));

    priceminRangeResiRent.map(items => parseInt(items.value) < parseInt(selectedMaxPriceValue) && minPriceLettings.push(items));

    if (minPriceLettings.length > 0) {
    } else {
        minPriceLettings.push(priceminRangeResiRent[0])
    }

    if (maxPriceLettings.length > 0) {
    } else {
        maxPriceLettings.push(pricemaxRangeResiRent[0])
    }
    // Lettings price list

    // 
    useEffect(() => {
        // 
        if (_.isEmpty(minPriceList)) {
          if (props.propertyType === "lettings") {
            setMinPriceList(priceminRangeResiRent);
            setMaxPriceList(pricemaxRangeResiRent);
          } else {
            setMinPriceList(priceminRangeResiSale)
            setMaxPriceList(pricemaxRangeResiSale)
          }
        }
        //
        
        // Page when load field in text boxes will same
        const url = typeof window !== 'undefined' ? window.location.href : ''
        let pathUri = url.split("/")

        for (let vi = 1; vi <= pathUri.length; vi++) {
          // check for area
            if (typeof pathUri[vi] === "undefined") {
                continue
            }
    
            // Property type
            if (pathUri[vi].indexOf("type-") >= 0) {
                setPropertyVal(pathUri[vi].replace("type-", ""))
            }
            // Property type
        
            // Area
            if ((pathUri[vi].indexOf("in-") == 0) && (pathUri[vi].indexOf("in-dubai") == -1)) {
                var areaArrfilt = (pathUri[vi].replace("in-", ""))
                if (areaArrfilt === "dubai") { areaArrfilt = "" }
                props.setAreaList(areaArrfilt.split("-and-"));

                var areaTag = areaArrfilt.split("-and-")
                var selectedAreaTag = []
            }
            // Area
        
            // Price
            if (
                pathUri[vi].indexOf("between-") >= 0 ||
                pathUri[vi].indexOf("above-") >= 0 ||
                pathUri[vi].indexOf("under-") >= 0
            ) {
                let priceFilt1 = pathUri[vi].split("above-")
                if (priceFilt1[1]) {
                    setMinPriceVal(priceFilt1[1])
                }
                let priceFilt2 = pathUri[vi].split("under-")
                if (priceFilt2[1]) {
                    setMaxPriceVal(priceFilt2[1])
                }
                let priceFilt3 = pathUri[vi].split("between-")
                if (priceFilt3[1]) {
                    let priceFilt4 = priceFilt3[1].split("-and-")
                    setMinPriceVal(priceFilt4[0])
                    setMaxPriceVal(priceFilt4[1])
                }
            }
            // Price
        }
        // Page when load field in text boxes will same
    },[]);
    // 

    // Multi area
	React.useEffect(() => {
        let filter = [];
        if (props.searchState?.refinementList?.search_areas || areasList.length > 0) {
            let search_areas = Array.isArray(props.searchState?.refinementList?.search_areas) ? props.searchState?.refinementList?.search_areas : [props.searchState?.refinementList?.search_areas];
			let filterArea = areasList.filter(c => search_areas.includes(c.slug))
            filter.push(...filterArea)
        }
        setAreasArr(filter)
    }, [areasList, props.searchState?.refinementList?.search_areas])
	// Multi area

    // Submit search
    const submitSearch = (e) => {

        // 
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")
        var buildingValue = propertyval ? propertyval : $(".property input[type='hidden']").val();
        var sortValue = sortval;
        let priceUrl = ""

        // console.log("$$$$--proeprty--",propertyval,buildingValue)

        if (maxpriceVal || minpriceVal) {
            if (minpriceVal && maxpriceVal && minpriceVal != "0" && maxpriceVal != "0") {
                priceUrl = "between-" + minpriceVal + "-and-" + maxpriceVal
            } else if ((minpriceVal == '' || minpriceVal == "0") && maxpriceVal && maxpriceVal != "0") {
                priceUrl = "under-" + maxpriceVal
            }
            else if (minpriceVal && (maxpriceVal == '' || maxpriceVal == "0") && minpriceVal != "0") {
                priceUrl = "above-" + minpriceVal
            }
        }

        var searchFilterResults = "";

        if(areaValue) {
            if(props.propertyMap === "map") {
                if(props.propertyType === "sales") {
                    searchFilterResults = "/off-plan-properties-map/for-sale/in-" + areaValue + "/";
                } else {
                    searchFilterResults = "/off-plan-properties-map/for-rent/in-" + areaValue + "/";
                }
            } else {
                if(props.propertyType === "sales") {
                    searchFilterResults = "/off-plan-properties/for-sale/in-" + areaValue + "/";
                } else {
                    searchFilterResults = "/off-plan-properties/for-rent/in-" + areaValue + "/";
                }
            }
        } else {
            if(props.propertyMap === "map") {
                if(props.propertyType === "sales") {
                    searchFilterResults = "/off-plan-properties-map/for-sale/in-dubai/";
                } else {
                    searchFilterResults = "/off-plan-properties-map/for-rent/in-dubai/";
                }
            } else {
                if(props.propertyType === "sales") {
                    searchFilterResults = "/off-plan-properties/for-sale/in-dubai/";
                } else {
                    searchFilterResults = "/off-plan-properties/for-rent/in-dubai/";
                }
            }
        }

        if(buildingValue){
            searchFilterResults += "type-" + buildingValue + "/";
        }

        if(priceUrl){
            searchFilterResults += priceUrl +"/";
        }

        if(sortValue) {
            searchFilterResults += sortValue +"/";
        }

        // if(props.sort_by){
        //     searchFilterResults += 'sortby-'+props.sort_by;
        // }

        props.setAreaList(areasArr.filter(c => typeof c.isDeveloper === 'undefined').map(c =>  c.slug));

        navigate(searchFilterResults);
    }
    // Submit search

    // Buy & Rent
    const onChangeSellRent = (e) => {
        gatsbyNavigate(`/${e.value}`)
    }
    // Buy & Rent

    return (
        <section className="filter-search-wrapper">
            <div className="filter-bg">
                <Container className="filter-features-container">
                    <Row>
                        <Col>
                            <div className="filter-search-features">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="search-bar-wrapper off-plan predictive-search-box">
                                        <SearchListBox 
                                            areaVal={areaVal} 
                                            placeholder={"Location, developer or project"} 
                                            setAreaVal={setAreaVal}
                                            setAreasList={setAreasList} 
                                            handleMultiAreaFun={handleMultiAreaFun} 
                                            autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                        />
                                    </div>
                                    <div className="search-price-wrapper d-xl-flex align-items-center d-none">
                                        <div class="search-divider"></div>
                                        <Select
                                            options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : (props.propertyType === "sales" ? minPrice : minPriceLettings)}
                                            isSearchable={false}
                                            placeholder="Min Price"
                                            placeholderValue={minpriceVal ? (props.propertyType === "sales" ? "AED"+minpriceVal : "AED"+minpriceVal) : "Min Price"}
                                            classNamePrefix={"react-select"}
                                            className="select-control price-dropdown"
                                            styles={customStyles}
                                            value={minPriceList.find(obj => obj.value === minpriceVal)}
                                            components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                        />
                                    </div>
                                    <div className="search-price-wrapper d-xl-flex align-items-center d-none">
                                        <div class="search-divider"></div>
                                        <Select
                                            options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : (props.propertyType === "sales" ? maxPrice : maxPriceLettings)}
                                            isSearchable={false}
                                            placeholder="Max Price"
                                            placeholderValue={maxpriceVal ? (props.propertyType === "sales" ? "AED"+maxpriceVal : "AED"+maxpriceVal) : "Max Price"}
                                            classNamePrefix={"react-select"}
                                            className="select-control price-dropdown"
                                            styles={customStyles}
                                            value={maxPriceList.find(obj => obj.value === maxpriceVal)}
                                            components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                                        />
                                    </div>

                                    <div className="search-property-wrapper d-xl-flex align-items-center d-none">
                                        <div class="search-divider"></div>
                                        <Select
                                            options={props.propertyType === "sales" ? resiSalesPropertyType : resiLettingsPropertyType}
                                            isSearchable={false}
                                            placeholder={propertyval ? propertyval : "Type"}
                                            className={"select-control"}
                                            classNamePrefix={"react-select"}
                                            styles={customStyles}
                                            value={props.department === "residential" ? resiSalesPropertyType.find(obj => obj.value === propertyval) : resiLettingsPropertyType.find(obj => obj.value === propertyval)}
                                            components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            onChange={e => setPropertyVal(e.value)}
                                        />
                                    </div>
                                    {/* <div className="search-completion-wrapper d-xl-flex align-items-center d-none">
                                        <div class="search-divider"></div>
                                        <Select
                                            options={completionRange}
                                            isSearchable={false}
                                            placeholder={completionval ? completionval : "Completion"}
                                            className={"select-control"}
                                            classNamePrefix={"react-select"}
                                            styles={customStyles}
                                            value={completionRange.find(obj => obj.value === completionval)}
                                            components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            onChange={e => setCompletionVal(e.value)}
                                        />
                                    </div> */}
                                    <div className="d-xl-none">
                                        <a href="javascript:void(0)" onClick={() => handleFilterShow()}>
                                            <i className="icon icon-search-sort"></i>
                                        </a>
                                    </div>
                                    <div className="search-btn-wrapper">
                                        <button className="search-btn" onClick={submitSearch}><i className="icon icon-search-btn"></i> <span className="d-md-inline-flex d-none">Search</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="predictive-arelist-suggestion">
                                <AreaListBox areas={areasArr} removeArea={removeArea} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Filter search */}
            <Modal show={filterShow} fullscreen={fullscreen} onHide={() => setFilterShow(false)} className="property-filter-modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h4 className="modal-heading">Filter your search</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="filter-search-mobile-wrapper">
                                <div>
                                    <div className="search-mobile-area-search-wrapper search-mobile-form-control predictive-search-box">
                                        <InputGroup>
                                            <InputGroup.Text><i className="icon icon-map-pin"></i></InputGroup.Text>
                                            <SearchListBox 
                                                areaVal={areaVal} 
                                                placeholder={"Location, developer or project"} 
                                                setAreaVal={setAreaVal}
                                                setAreasList={setAreasList} 
                                                handleMultiAreaFun={handleMultiAreaFun} 
                                                autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                            />
                                        </InputGroup>
                                    </div>

                                    <div className="predictive-arelist-suggestion suggestion-mobile">
                                        <AreaListBox areas={areasArr} removeArea={removeArea} />
                                    </div>
                                </div>
                                <div className="search-mobile-price-wrapper search-mobile-form-control">
                                    <Select
                                        options={parseInt(selectedMaxPriceValue) === 0 ? minPriceList : (props.propertyType === "sales" ? minPrice : minPriceLettings)}
                                        isSearchable={false}
                                        placeholder="Min Price"
                                        placeholderValue={minpriceVal ? (props.propertyType === "sales" ? "AED"+minpriceVal : "AED"+minpriceVal) : "Min Price"}
                                        classNamePrefix={"select-opt"}
                                        className="select-control price-dropdown"
                                        styles={customStyles}
                                        value={minPriceList.find(obj => obj.value === minpriceVal)}
                                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                        onChange={e => { onChangeMinPrice(e); setMinPriceVal(e.value) }}
                                    />
                                </div>
                                <div className="search-mobile-price-wrapper search-mobile-form-control">
                                    <Select
                                        options={parseInt(selectedMinPriceValue) === 0 ? maxPriceList : (props.propertyType === "sales" ? maxPrice : maxPriceLettings)}
                                        isSearchable={false}
                                        placeholder="Max Price"
                                        placeholderValue={maxpriceVal ? (props.propertyType === "sales" ? "AED"+maxpriceVal : "AED"+maxpriceVal) : "Max Price"}
                                        classNamePrefix={"select-opt"}
                                        className="select-control price-dropdown"
                                        styles={customStyles}
                                        value={maxPriceList.find(obj => obj.value === maxpriceVal)}
                                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                        onChange={e => { onChangeMaxPrice(e); setMaxPriceVal(e.value) }}
                                    />
                                </div>
                                <div className="search-mobile-property-wrapper search-mobile-form-control">
                                    <Select
                                        options={props.propertyType === "sales" ? resiSalesPropertyType : resiLettingsPropertyType}
                                        isSearchable={false}
                                        placeholder={propertyval ? propertyval : "Property Type"}
                                        className={"select-control"}
                                        styles={customStyles}
                                        value={props.department === "residential" ? resiSalesPropertyType.find(obj => obj.value === propertyval) : resiLettingsPropertyType.find(obj => obj.value === propertyval)}
                                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                        onChange={e => setPropertyVal(e.value)}
                                    />
                                </div>
                                {/* <div className="search-mobile-completion-wrapper search-mobile-form-control">
                                    <Select
                                        options={completionRange}
                                        isSearchable={false}
                                        placeholder={completionval ? completionval : "Completion"}
                                        className={"select-control"}
                                        classNamePrefix={"react-select"}
                                        styles={customStyles}
                                        value={completionRange.find(obj => obj.value === completionval)}
                                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                        onChange={e => setCompletionVal(e.value)}
                                    />
                                </div> */}
                                <div className="search-mobile-sort-wrapper search-mobile-form-control d-md-none">
                                    <Select
                                        options={sortFilterOffplan}
                                        isSearchable={false}
                                        // placeholder={"Most Recent"}
                                        placeholder={sortval ? sortval : "Most Recent"}
                                        className={"select-control"}
                                        styles={customStyles}
                                        value={sortFilterOffplan.find(obj => obj.value === sortval)}
                                        components={{ DropdownIndicator:() => <i className="icon icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                        onChange={e => setSortVal(e.value)}
                                    />
                                </div>
                                <div className="search-mobile-btn-wrapper">
                                    <button className="search-btn" onClick={() => {setFilterShow(false); submitSearch()} }>Update results</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {/* Filter search */}
        </section>
    )
}

export default OffplanFilterSearch